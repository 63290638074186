<template>
  <div class="container">
    <header class="header">
      <header-big :header="home" />
    </header>
    <main id="home">
      <collapsible-text class="home-intro" :text="home.description" />
      <ul class="home-list">
        <li>
          <image-button
            image="/assets/images/intro_0.jpg"
            @onImageButton="onIntroduction"
          />
          <p class="home-title">{{ home.introduction }}</p>
        </li>
        <li>
          <image-button
            image="/assets/images/intro_1.jpg"
            @onImageButton="onTour"
          />
          <p class="home-title">{{ home.tour }}</p>
        </li>
        <li>
          <image-button
            image="/assets/images/intro_2.jpg"
            @onImageButton="onTimeline"
          />
          <p class="home-title">{{ home.timeline }}</p>
        </li>
      </ul>
    </main>
    <footer>
      <button class="footer-button" @click="onImprint">
        {{ home.imprint }}
      </button>
    </footer>
  </div>
</template>

<script>
import HeaderBig from "./HeaderBig.vue";
import CollapsibleText from "./CollapsibleText.vue";
import ImageButton from "./ImageButton.vue";

export default {
  name: "Home",
  components: {
    HeaderBig,
    CollapsibleText,
    ImageButton,
  },
  methods: {
    onIntroduction() {
      this.$router.push("/introduction");
    },
    onTour() {
      this.$router.push("/tour");
    },
    onTimeline() {
      this.$router.push("/timeline");
    },
    onImprint() {
      this.$router.push("/imprint");
    },
  },
  data() {
    return {
      home: this.$root.$data.strings.home,
    };
  },
};
</script>
