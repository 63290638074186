const exhibition = require("../../public/assets/data/exhibition.json");
const media = require("../../public/assets/data/media.json");
const register = require("../../public/assets/data/register.json");
const timeline = require("../../public/assets/data/timeline.json");

class Exhibition {

    constructor() {
        this.buildings = exhibition.buildings
        this.poisOutside = exhibition.pois
        this.assets = media
        this.register = register
        this.timeline = timeline
        this.introduction = exhibition.introduction
        this.addRegisterEntries()
    }

    addRegisterEntries() {
        for (const asset of Object.values(media)) {
            if (!asset.register) continue
            for (const [alias, entry] of Object.entries(asset.register)) {
                const keyword = {
                    name: alias,
                    text: this.register.entries[entry].text
                }
                asset.keywords.push(keyword)
            }
        }
    }

    getAssetWithId(id) {
        return this.assets[id]
    }

    getAssetForPoi(poi) {
        return this.assets[poi.asset]
    }

    getAssetForPoiOutside(index) {
        const asset = this.poisOutside[index].asset
        return this.assets[asset]
    }
}

export default Exhibition
