<template>
  <div class="poi-overlay">
    <header class="header">
      <header-small
        :header="asset.name"
        @onSmallHeaderClose="onSmallHeaderClose"
      />
    </header>
    <main :class="poiStyle">
      <div v-if="showsVideo" :class="assetContainerStyle">
        <div :class="assetWrapperStyle">
          <video-player
            class="asset"
            :options="videoOptions"
            @VideoPlayerUserActive="videoPlayerUserActive"
            @VideoPlayerFinished="videoPlayerFinished"
          />
          <div
            v-show="showsVideoCloseButton"
            class="video-close-button"
            @click="onCloseVideo"
          />
        </div>
      </div>
      <div v-if="!showsVideo" :class="assetContainerStyle">
        <img class="image-asset" :src="imageSrc" />
      </div>
      <poi-list :class="poiListStyle" :asset="asset" />
    </main>
  </div>
</template>

<script>
import HeaderSmall from "./HeaderSmall.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import PoiList from "./PoiList.vue";
export default {
  name: "Poi",
  props: ["asset"],
  components: {
    HeaderSmall,
    VideoPlayer,
    PoiList,
  },
  computed: {
    videoOptions() {
      return {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: "/videos/" + this.asset.video,
            type: "video/mp4",
          },
        ],
      };
    },
    imageSrc() {
      return "/assets/images/" + this.asset.image;
    },
    showsVideo() {
      if (this.asset.image) {
        return false;
      }
      return true;
    },
    poiStyle() {
      if (this.asset.keywords.length === 0) {
        return "poi-full";
      }
      return "poi";
    },
    assetContainerStyle() {
      if (this.asset.keywords.length === 0) {
        return "asset-container-full";
      }
      return "asset-container";
    },
    assetWrapperStyle() {
      if (this.asset.keywords.length === 0) {
        return "asset-wrapper-full";
      }
      return "asset-wrapper";
    },
    poiListStyle() {
      if (this.asset.keywords.length === 0) {
        return "poi-list-gone";
      }
      return "poi-list";
    },
  },
  methods: {
    onSmallHeaderClose() {
      this.$emit("onPoiClose");
    },
    videoPlayerFinished() {
      if (this.asset.keywords.length === 0) {
        this.onCloseVideo();
      } else {
        this.showsFullscreen = false;
      }
    },
    videoPlayerUserActive(show) {
      this.showsVideoCloseButton = show;
    },
    onCloseVideo() {
      this.onSmallHeaderClose();
    },
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
  destroyed() {
    document.body.classList.remove("no-scroll");
  },
  data() {
    return {
      showsFullscreen: false,
      showsVideoCloseButton: false,
    };
  },
};
</script>

<style scoped>
.poi-full {
  width: 100%;
  height: calc(100vh - var(--height-header));
  max-height: calc(100vh - var(--height-header));
  padding-left: 0;
  padding-right: var(--inset-main);
  overflow: hidden;
}

.asset-container-full {
  max-width: calc((100vh - var(--height-header)) * 1.77778);
  max-height: calc(100vh - var(--height-header));
}

.asset-wrapper-full {
  position: relative;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
}

.asset-container {
  width: 50vw;
  margin-right: 2%;
}

.asset-wrapper {
  width: 100%;
}

.asset {
  width: 100%;
  box-shadow: 0 4px 8px 0 var(--color-medium-alpha),
    0 6px 20px 0 var(--color-medium-light-alpha);
}

.image-asset {
  width: 100%;
  box-shadow: 0 4px 8px 0 var(--color-medium-alpha),
    0 6px 20px 0 var(--color-medium-light-alpha);
}

/* ---------------- */

.poi-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: white;
}

.poi {
  width: 100%;
  height: calc(100vh - var(--height-header));
  max-height: calc(100vh - var(--height-header));
  padding-left: 0;
  padding-right: var(--inset-main);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.poi-list {
  width: 50vw;
  visibility: inherit;
  overflow: hidden;
}

.poi-list-gone {
  visibility: collapse;
  overflow: hidden;
}

.video-close-button {
  visibility: collapse;
}

.video-close-button-fullscreen:hover,
.video-close-button-fullscreen:active {
  opacity: 0.8;
}

@media screen and (min-width: 1200px) {
  .asset-container {
    width: 60vw;
    flex-grow: 2;
  }
  .poi-list {
    width: 40vw;
  }
}

@media screen and (orientation: portrait) {
  .poi {
    flex-direction: column;
    padding-right: 0;
  }

  .asset-container {
    flex-grow: 0;
    margin-right: 0;
    width: 100%;
  }

  .asset-container-full {
    flex-grow: 0;
    margin-right: 0;
    width: 100%;
  }

  .poi-list {
    width: 100vw;
    padding: var(--inset-main-small) var(--inset-main-small);
    padding-bottom: 0;
    overflow: hidden;
    flex-grow: 1;
  }

  .poi-overlay {
    height: 120%;
  }
}
</style>
