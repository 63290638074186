<template>
  <div class="container">
    <header class="header">
      <header-big :header="imprint" :showsBack="true" back="/" />
    </header>
    <main id="imprint">
      <div id="imprint-container">
        <p id="caption">{{ imprint.caption }}</p>
        <pre><p id="address" v-html="imprint.address"></p></pre>
        <p>
          <a class="link" :href="imprint.link_1" target="_blank">{{
            imprint.link_1
          }}</a
          ><br />
          <a class="link" :href="imprint.link_2" target="_blank">{{
            imprint.link_2
          }}</a>
          <br />
          <a class="link" :href="'mailto:' + imprint.link_3">{{
            imprint.link_3
          }}</a>
        </p>
        <a :href="imprint.link_5" target="_blank"
          ><img class="logo" src="/assets/images/zzf_logo.jpeg"
        /></a>
        <p id="foundation">{{ imprint.foundation }}</p>
        <a :href="imprint.link_4" target="_blank">
          <img class="logo" src="/assets/svgs/landesbeauftragte.svg"
        /></a>
      </div>
    </main>
    <footer id="imprint-footer">
      <button class="footer-button" @click="onCredits">
        {{ credits.headline }}
      </button>
    </footer>
  </div>
</template>

<script>
import HeaderBig from "./HeaderBig.vue";
export default {
  name: "Imprint",
  components: {
    HeaderBig,
  },
  methods: {
    onCredits() {
      this.$router.push("/credits");
    },
  },
  data() {
    return {
      imprint: this.$root.$data.strings.imprint,
      credits: this.$root.$data.strings.credits,
    };
  },
};
</script>

<style scoped>
#imprint-container {
  padding-left: var(--inset-main);
}

#foundation {
  margin-top: var(--margin-vertical);
  margin-bottom: var(--margin-vertical-small);
}

#caption {
  font-family: Maxima;
  margin-bottom: var(--margin-header-vertical);
}

#address {
  max-width: 40%;
}

.link {
  font-family: Maxima-light;
  color: var(--color-medium);
}

.logo {
  max-height: 4em;
}

main p {
  margin-bottom: 1em;
}

@media screen and (max-width: 680px) {
  #imprint-container {
    padding-left: 0;
  }
}
</style>
