<template>
  <div class="popup-container" @click="onClose">
    <p>{{ entry.name }}</p>
    <p>{{ entry.text }}</p>
  </div>
</template>

<script>
export default {
  name: "RegisterPopup",
  props: ["entry"],
  methods: {
    onClose() {
      this.$emit("onClosePopup");
    },
  },
};
</script>

<style scoped>
.popup-container {
  background-color: white;
  box-shadow: 0 4px 8px 0 var(--color-medium-alpha),
    0 6px 20px 0 var(--color-medium-light-alpha);
  padding: 1em;
}

.popup-container p:nth-child(1) {
  font-family: Maxima;
}

.popup-container p:nth-child(2) {
  font-size: var(--font-size-medium);
}
</style>
