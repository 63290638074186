<template>
  <div>
    <ul>
      <li v-for="(f, index) in floors" :key="f.id">
        <input
          type="radio"
          :id="f.id"
          :value="f.id"
          v-model="selected"
          v-on:change="onChange(index)"
        />
        <label :for="f.id">
          <p>{{ f.name }}</p>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FloorSelector",
  props: ["floors", "floor", "onChange"],
  data() {
    return {
      selected: this.floors[this.floor].id,
    };
  },
};
</script>

<style scoped>
div {
  margin: 0 var(--inset-main);
}
ul {
  display: flex;
  flex-direction: column-reverse;
}
li {
  margin-top: var(--margin-vertical-small);
}

p {
  display: inline-block;
  margin-left: 0.2em;
}

@media screen and (max-width: 660px) {
  div {
    margin: 0 var(--inset-main-small);
  }
  p {
    font-size: var(--font-size-small);
  }
}
</style>
