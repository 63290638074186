<template>
  <div class="header-container">
    <div class="text">
      <p class="headline">{{ header.headline }}</p>
      <p class="subheading">{{ header.subheading }}</p>
    </div>
    <div class="button">
      <button class="plastic-button" v-if="showsBack" @click="onBack">
        {{ title }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["header", "showsBack", "back"],
  name: "HeaderBig",
  data() {
    return {
      title: this.$root.$data.strings.back,
    };
  },
  methods: {
    onBack() {
      this.$emit("onHeaderBack");
      this.$router.push(this.back);
    },
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
}

.text {
  flex: 6 1 0;
}

.button {
  flex: 1 1 0;
}

.button button {
  float: right;
}

.headline {
  font-family: Maxima;
  font-size: var(--font-size-headline);
  margin-bottom: var(--margin-header-vertical);
}

.subheading {
  font-size: var(--font-size-medium);
}

@media screen and (max-width: 660px) {
  .header-container {
    margin-top: var(--margin-vertical);
  }
  .headline {
    font-size: var(--font-size-headline-small);
    max-width: var(--percentage-width-header);
  }
  .subheading {
    font-size: var(--font-size-small);
  }
}
</style>
