<template>
  <div class="poi-container">
    <p class="poi-list-header">{{ poi.sectionHeadline }}</p>
    <ul class="poi-ul" ref="poiList" @click="onTapBackground">
      <poi-list-item
        v-for="(keyword, index) in asset.keywords"
        :key="keyword.name"
        :keyword="keyword"
        :index="index"
        :poi="poi"
        :isOpened="isOpened(index)"
        @onRegisterEntry="onRegisterEntry"
      />
    </ul>
    <register-popup
      id="poi-list-popup"
      ref="poiListPopup"
      :style="popupStyle"
      v-show="showPopup"
      :entry="registerEntry"
      @onClosePopup="onClosePopup"
    />
  </div>
</template>

<script>
import PoiListItem from "./PoiListItem.vue";
import RegisterPopup from "./RegisterPopup.vue";
export default {
  name: "PoiList",
  props: ["asset"],
  components: {
    PoiListItem,
    RegisterPopup,
  },
  data() {
    return {
      poi: this.$root.$data.strings.poi,
      windowWidth: 0,
      maxWidth: 660,
      registerEntry: { name: "", text: "" },
      showPopup: false,
      popupStyle: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    onRegisterEntry(alias, registerEntry, keyword, y) {
      const word = keyword.register[alias];
      const entry = this.$root.$data.exhibition.register.entries[word];
      this.registerEntry = entry;
      this.showPopup = true;
      this.$nextTick(function () {
        this.popupStyle = this.getPopupStyle(y);
      });
    },
    onTapBackground(e) {
      e.preventDefault();
      this.showPopup = false;
    },
    onClosePopup() {
      this.showPopup = false;
      this.popupStyle = "";
    },
    getPopupStyle(y) {
      const listRect = this.$refs.poiList.getBoundingClientRect();
      const popupRect = this.$refs.poiListPopup.$el.getBoundingClientRect();

      const topY = y - listRect.y;

      const bottomEdge = listRect.height;
      const lowerGap = bottomEdge - (topY + popupRect.height);

      const clampedTopY = Math.max(0, topY);
      const clampedBottomY = Math.max(0, lowerGap);

      // fit top and bottom
      if (popupRect.height > listRect.height) {
        return "top: 0px; bottom: 0px; overflow: auto";
      }
      // float top or bottom
      if (lowerGap < 0) {
        return "bottom: " + clampedBottomY + "px;";
      }
      return "top: " + clampedTopY + "px;";
    },

    isOpened(index) {
      return index === 0 && this.windowWidth > this.maxWidth;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.poi-container {
  position: relative;
  height: 100%;
}

#poi-list-popup {
  position: absolute;
  display: block;
  margin: 1em 1em;
  margin-top: 1em;
}

.poi-list-header {
  height: 2em;
}

.poi-ul {
  padding-bottom: var(--inset-main);
  overflow: auto;
  height: calc(100% - 2em);
}

@media screen and (max-width: 660px) {
  .poi-container {
    position: relative;
    height: 100%;
  }

  .poi-list-header {
    font-size: var(--font-size);
    height: 1.5em;
  }

  .poi-ul {
    padding-bottom: var(--inset-main);
    overflow: auto;
    height: calc(100% - 1.5);
  }
}
</style>
