<template>
  <div>
    <p>
      <span v-if="!readMoreActivated" v-html="text.short" />
      <a v-if="!readMoreActivated" @click="activateReadMore" href="#">
        {{ more }}
      </a>
      <span v-if="readMoreActivated" v-html="text.long" />
      <a v-if="readMoreActivated" @click="activateReadLess" href="#">
        {{ less }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {
      more: this.$root.$data.strings.more,
      less: this.$root.$data.strings.less,
      readMoreActivated: false,
    };
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
    activateReadLess() {
      this.readMoreActivated = false;
    },
  },
};
</script>
