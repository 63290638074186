<template>
  <div>
    <video
      ref="videoPlayer"
      class="vjs-perl video-js"
      data-setup='{"fluid": true}'
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    const thus = this;
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        thus.player.on("ended", function () {
          thus.$emit("VideoPlayerFinished");
        });
        thus.player.on("useractive", () => {
          thus.$emit("VideoPlayerUserActive", true);
        });
        thus.player.on("userinactive", () => {
          thus.$emit("VideoPlayerUserActive", false);
        });
      }
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
