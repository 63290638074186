<template>
  <span ref="registerEntry" class="register-entry" @click="onClick">{{
    text
  }}</span>
</template>

<script>
export default {
  name: "RegisterSpan",
  props: ["text"],
  methods: {
    onClick(e) {
      e.stopPropagation();
      const y = this.$refs.registerEntry.getBoundingClientRect().y;
      this.$parent.$emit("onRegisterEntry", this.text, y);
    },
  },
};
</script>

<style scoped>
.register-entry {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-register-link);
}
</style>
