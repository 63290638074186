<template>
  <transition-page>
    <router-view />
  </transition-page>
</template>

<script>
import TransitionPage from "./transitions/TransitionPage.vue";

export default {
  name: "App",
  components: {
    TransitionPage,
  },
};
</script>
