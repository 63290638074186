<template>
  <div class="container">
    <header class="header">
      <header-big :header="credits" :showsBack="true" back="/imprint" />
    </header>
    <main id="credits">
      <ul>
        <credit-item
          v-for="entry in credits.entries"
          :key="entry.headline"
          :entry="entry"
        />
      </ul>
    </main>
  </div>
</template>

<script>
import HeaderBig from "./HeaderBig.vue";
import CreditItem from "./CreditItem.vue";
export default {
  name: "Credits",
  components: {
    HeaderBig,
    CreditItem,
  },
  data() {
    return {
      credits: this.$root.$data.strings.credits,
    };
  },
};
</script>
