<template>
  <div :class="tourClass">
    <header class="header">
      <header-big :header="tour" :showsBack="true" back="/" />
    </header>
    <main id="tour">
      <collapsible-text class="site-introduction" :text="tour.description" />
      <div class="site-flexer">
        <div class="site-wrapper">
          <button class="poi-button" @click="onPoi(3)">
            {{ exhibition.getAssetForPoiOutside(3).name }}
          </button>

          <button class="building-button" @click="onBuilding(1)">
            <img
              class="building-button-image"
              src="/assets/svgs/building1_frame0.svg"
            />
            <p class="building-button-text">{{ buildings[1].name }}</p>
          </button>
        </div>
        <div class="site-wrapper">
          <button class="poi-button" @click="onPoi(0)">
            {{ exhibition.getAssetForPoiOutside(0).name }}
          </button>
          <button class="poi-button" @click="onPoi(1)">
            {{ exhibition.getAssetForPoiOutside(1).name }}
          </button>
          <button class="poi-button" @click="onPoi(2)">
            {{ exhibition.getAssetForPoiOutside(2).name }}
          </button>
          <button class="poi-button" @click="onPoi(4)">
            {{ exhibition.getAssetForPoiOutside(4).name }}
          </button>

          <button class="building-button" @click="onBuilding(0)">
            <img
              class="building-button-image"
              src="/assets/svgs/building0_frame0.svg"
            />
            <div class="building-button-fill"></div>
            <p class="building-button-text">{{ buildings[0].name }}</p>
          </button>
          <p>{{ tour.entrance }}</p>
        </div>
      </div>
    </main>
    <transition name="fade">
      <poi v-if="showsPoi" :asset="assetForPoi" @onPoiClose="onPoiClose"></poi>
    </transition>
  </div>
</template>

<script>
import HeaderBig from "./HeaderBig.vue";
import CollapsibleText from "./CollapsibleText.vue";
import Poi from "./Poi.vue";

export default {
  name: "Tour",
  props: ["poi"],
  components: {
    HeaderBig,
    CollapsibleText,
    Poi,
  },
  data() {
    return {
      tour: this.$root.$data.strings.tour,
      exhibition: this.$root.$data.exhibition,
      buildings: this.$root.$data.exhibition.buildings,
      selectedPoi: this.$root.$data.exhibition.poisOutside[this.poi],
      showsPoi: this.poi !== undefined,
    };
  },
  computed: {
    tourClass() {
      if (this.showsPoi) {
        return "tour-noscroll";
      }
      return "tour-scroll";
    },
    assetForPoi() {
      return this.exhibition.getAssetWithId(this.selectedPoi.asset);
    },
  },
  methods: {
    onBuilding(index) {
      this.$router.push("/building/" + index + "/floor/0");
    },
    onPoi(index) {
      this.selectedPoi = this.exhibition.poisOutside[index];
      this.$router.replace("/tour/poi/" + index);
      this.showsPoi = true;
    },
    onPoiClose() {
      this.$router.replace("/tour");
      this.showsPoi = false;
    },
  },
};
</script>

<style scoped>
.tour-scroll {
  overflow: inherit;
}

.tour-noscroll {
  overflow: hidden;
}

.site-flexer {
  position: relative;
  display: flex;
  flex-direction: row;
}

.site-wrapper {
  position: relative;
  width: 42vw;
  height: 40vh;
  margin-top: 0em;
}

.site-wrapper:nth-child(2) {
  width: 58vw;
}

.site-wrapper > * {
  position: absolute;
  font-size: 1.4vw;
}

.site-wrapper > p {
  top: calc(24.9 * var(--tour-frac));
  left: calc(28 * var(--tour-frac));
}

.poi-button {
  background-color: var(--color-light);
}

.poi-button:hover {
  background-color: var(--floor-button-highlight);
}

.building-button {
  position: relative;
}

.building-button:hover {
  background-image: url("/assets/svgs/building0_frame0_fill.svg");
  background-repeat: no-repeat;
}

.building-button-text {
  position: absolute;
  top: 40%;
  width: 100%;
  text-align: center;
}

.site-wrapper:nth-child(2) > .poi-button:nth-child(1) {
  top: calc(13 * var(--tour-frac));
  left: calc(37 * var(--tour-frac));
  width: calc(12 * var(--tour-frac));
  height: calc(10.2 * var(--tour-frac));
}

.site-wrapper:nth-child(2) > .poi-button:nth-child(2) {
  top: calc(7 * var(--tour-frac));
  left: calc(26 * var(--tour-frac));
  width: calc(10 * var(--tour-frac));
  height: calc(5 * var(--tour-frac));
}

.site-wrapper:nth-child(2) > .poi-button:nth-child(3) {
  top: calc(7 * var(--tour-frac));
  left: calc(6 * var(--tour-frac));
  width: calc(10 * var(--tour-frac));
  height: calc(5 * var(--tour-frac));
}

.site-wrapper:nth-child(2) > .poi-button:nth-child(4) {
  top: calc(24 * var(--tour-frac));
  left: calc(6 * var(--tour-frac));
  width: calc(10 * var(--tour-frac));
  height: calc(5 * var(--tour-frac));
}

.site-wrapper:nth-child(1) > .poi-button {
  top: calc(7 * var(--tour-frac));
  left: calc(24 * var(--tour-frac));
  width: calc(12 * var(--tour-frac));
  height: calc(5 * var(--tour-frac));
}

.site-wrapper:nth-child(2) > .building-button {
  top: calc(13 * var(--tour-frac));
  left: calc(0 * var(--tour-frac));
  width: calc(36 * var(--tour-frac));
}

.site-wrapper:nth-child(1) > .building-button:hover {
  background-image: url("/assets/svgs/building1_frame0_fill.svg");
  background-repeat: no-repeat;
}

.site-wrapper:nth-child(1) > .building-button {
  top: calc(12.4 * var(--tour-frac));
  left: calc(5 * var(--tour-frac));
  width: calc(32 * var(--tour-frac));
}

@media screen and (max-width: 660px) {
  .site-flexer {
    align-items: left;
    flex-direction: column;
  }

  .site-wrapper {
    width: 90vw;
    height: 55vw;
  }

  .site-wrapper:nth-child(1) {
    transform: translate(-24vw);
  }

  .site-wrapper:nth-child(2) {
    width: 90vw;
    transform: translate(-12vw);
  }

  .site-wrapper > * {
    position: absolute;
    font-size: 3.2vw;
  }
}
</style>
