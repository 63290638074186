<template>
  <div class="header-container">
    <div class="text">
      <p class="headline">{{ header }}</p>
    </div>
    <div class="button" @click="onClose" />
  </div>
</template>

<script>
export default {
  props: ["header"],
  name: "HeaderBig",
  methods: {
    onClose() {
      this.$emit("onSmallHeaderClose");
    },
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
}

.text {
  flex: 6 1 0;
}

.button {
  width: 2em;
  height: 2em;
  background: url("/assets/svgs/close_poi_dark.svg") no-repeat;
  opacity: 1;
}

.button:hover,
.button:active {
  opacity: 0.8;
}

.headline {
  font-family: Maxima;
  font-size: var(--font-size-headline);
  margin-bottom: var(--margin-header-vertical);
}

@media screen and (max-width: 660px) {
  .header-container {
    margin-top: var(--margin-vertical);
  }
  .headline {
    font-size: var(--font-size-headline-small);
    max-width: var(--percentage-width-header);
  }

  .button {
    width: var(--font-size-headline-small);
    height: var(--font-size-headline-small);
  }
}
</style>
