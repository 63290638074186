<template>
  <div class="poi-list-item">
    <div class="poi-list-item-headline" @click="isVisible = !isVisible">
      <p>
        {{ poi.groupHeadline + keyword.name }}
      </p>
      <img
        :class="indicatorClass"
        src="/assets/svgs/poi_indicator_opened.svg"
      />
    </div>
    <div v-show="isVisible">
      <component
        v-bind:is="enhancedKeywordText"
        @onRegisterEntry="onRegisterEntry"
      />
      <img
        v-if="keyword.image"
        class="poi-list-item-image"
        :src="'/assets/images/' + keyword.image"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import RegisterSpan from "./RegisterSpan.vue";
Vue.component("register-span", RegisterSpan);

export default {
  name: "PoiListItem",
  props: ["poi", "keyword", "index", "isOpened"],
  computed: {
    indicatorClass() {
      return this.isVisible ? "poi-indicator-opened" : "poi-indicator-closed";
    },
    enhancedKeywordText() {
      let text = this.keyword.text;
      if (this.keyword.register) {
        for (const alias of Object.keys(this.keyword.register)) {
          let regex = new RegExp(alias);
          text = text.replace(
            regex,
            '<register-span text="' + alias + '"></register-span>'
          );
        }
      }
      return {
        template: "<p>" + text + "</p>",
      };
    },
  },
  methods: {
    onRegisterEntry(alias, y) {
      this.$emit("onRegisterEntry", alias, this.registerEntry, this.keyword, y);
    },
  },
  data() {
    return {
      isVisible: this.isOpened,
    };
  },
};
</script>

<style scoped>
.poi-list-item {
  margin-right: var(--inset-main-small);
  margin-bottom: 1em;
}

.poi-list-item-headline {
  display: flex;
  cursor: pointer;
}

.poi-list-item-image {
  margin: var(--margin-vertical-small) 0;
  width: 100%;
  height: auto;
}

.poi-list-item > div:nth-child(2) > p {
  font-size: var(--font-size-medium);
}

img {
  margin-left: 0.5em;
}

p {
  line-height: 1.3em;
}

.poi-indicator-opened {
  width: 1em;
  height: auto;
}

.poi-indicator-closed {
  width: 1em;
  height: auto;
  transform: rotate(-90deg);
}

@media screen and (max-width: 660px) {
  div > p {
    font-size: var(--font-size);
  }
}
</style>
