<template>
  <div class="container">
    <header class="header">
      <header-big :header="introductionStrings" :showsBack="true" back="/" />
    </header>
    <main id="introduction">
      <video-player :options="videoOptions" />
      <div id="introduction-description">
        <p v-html="introductionStrings.description_1" />
        <p v-html="introductionStrings.description_2" />
      </div>
    </main>
  </div>
</template>

<script>
import HeaderBig from "./HeaderBig.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "Introduction",
  components: {
    HeaderBig,
    VideoPlayer,
  },
  computed: {
    videoOptions() {
      return {
        isFullscreen: true,
        autoplay: true,
        controls: true,
        sources: [
          {
            src:
              "/videos/" +
              this.exhibition.getAssetWithId(this.introduction).video,
            type: "video/mp4",
          },
        ],
      };
    },
  },
  data() {
    return {
      introductionStrings: this.$root.$data.strings.introduction,
      introduction: this.$root.$data.exhibition.introduction,
      exhibition: this.$root.$data.exhibition,
    };
  },
};
</script>
<style scoped>
#introduction-description {
  display: flex;
  justify-content: space-between;
  margin: var(--margin-vertical) 0;
  padding-bottom: 2em;
}
p {
  width: 48%;
}

@media screen and (max-width: 660px) {
  #introduction-description {
    display: block;
  }
  p {
    width: 100%;
  }
}
</style>