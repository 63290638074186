<template>
  <div>
    <header class="header">
      <header-big :header="timelineStrings" :showsBack="true" back="/" />
    </header>
    <main id="timeline">
      <ul class="timeline-list">
        <timline-event
          :class="timelineEventClass(index)"
          v-for="(event, index) in this.timeline.events"
          :key="event.date"
          :event="event"
          :asset="assetForEvent(event)"
          @onTimelineEvent="onTimelineEvent(index)"
        />
      </ul>
      <transition name="fade">
        <poi
          v-if="showsPoi"
          :asset="selectedAsset"
          @onPoiClose="onPoiClose"
        ></poi>
      </transition>
    </main>
  </div>
</template>

<script>
import HeaderBig from "./HeaderBig.vue";
import TimlineEvent from "./TimlineEvent.vue";
import Poi from "./Poi.vue";
export default {
  name: "Timeline",
  props: ["event"],
  components: {
    HeaderBig,
    TimlineEvent,
    Poi,
  },
  data() {
    return {
      timelineStrings: this.$root.$data.strings.timeline,
      timeline: this.$root.$data.exhibition.timeline,
      exhibition: this.$root.$data.exhibition,
      showsPoi: this.event !== undefined,
      selectedEvent: this.$root.$data.exhibition.timeline.events[this.event],
    };
  },
  computed: {
    selectedAsset() {
      return this.exhibition.getAssetWithId(this.selectedEvent.personal);
    },
  },
  methods: {
    timelineEventClass(index) {
      if (index === 0) {
        return "timeline-event-first";
      }
      if (index === this.timeline.events.length - 1) {
        return "timeline-event-last";
      }
      return "timeline-event";
    },
    assetForEvent(event) {
      return this.exhibition.getAssetWithId(event.personal);
    },
    onTimelineEvent(index) {
      this.selectedEvent = this.exhibition.timeline.events[index];
      this.$router.replace("/timeline/" + index);
      this.showsPoi = true;
    },
    onPoiClose() {
      this.$router.replace("/timeline");
      this.showsPoi = false;
    },
  },
};
</script>

<style scoped>
#timeline {
  background-color: var(--color-background-light);
  padding: 0;
}
.timeline-list {
  height: calc(100vh - var(--height-header));
  min-height: 40vw;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}
.timeline-event {
  flex: 0 0 auto;
}

.timeline-event-first {
  flex: 0 0 auto;
  margin-left: 2em;
}
.timeline-event-last {
  flex: 0 0 auto;
  margin-right: 2em;
}

@media screen and (max-width: 660px) {
  .timeline-list {
    height: calc(100vh - var(--height-header));
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .timeline-event-first {
    margin-left: 0;
    margin-top: 1em;
  }
  .timeline-event-last {
    margin-right: 0;
    margin-bottom: 1em;
  }
}
</style>