<template>
  <div id="building" ref="building" :class="buildingClass">
    <header class="header">
      <header-big :header="buildingHeader" :showsBack="true" back="/tour" />
    </header>
    <floor-selector
      v-if="showsFloorSelector"
      :onChange="changeFloor"
      :floors="floors"
      :floor="floor"
    />
    <pinch-zoom
      ref="PinchZoom"
      id="floor-zoomer"
      backgroundColor="rgba(255,255,255,1)"
      overflow="visible"
      :minScale="scale"
      :limitZoom="maxScale"
      :zoomControlScale="avgScale"
      :doubleTapScale="avgScale"
      disableZoomControl="disable"
      @didScale="didScale"
    >
      <div id="floor-zoomer-div">
        <transition name="fade">
          <div
            :is="currentFloor"
            :building="building"
            :floor="floor"
            :displayLevel="displayLevel"
            :scale="scale"
            @onPoiItem="onPoiItem"
            @onRoomClick="onRoomClick"
          ></div>
        </transition>
      </div>
    </pinch-zoom>
    <transition name="fade">
      <div v-if="showZoomControl" class="zoom-control" @click="zoomBack" />
    </transition>
    <transition name="fade">
      <poi v-if="showsPoi" :asset="assetForPoi" @onPoiClose="onPoiClose"></poi>
    </transition>
  </div>
</template>

<script>
import HeaderBig from "./HeaderBig.vue";
import FloorSelector from "./FloorSelector.vue";
import Poi from "./Poi.vue";
import PinchZoom from "vue-pinch-zoom/dist/vue-pinch-zoom.common";
export default {
  name: "Building",
  props: ["building", "floor", "room", "poi"],
  components: {
    HeaderBig,
    FloorSelector,
    PinchZoom,
    Poi,
    "building0-floor0": () => import("./Floor_0_0"),
    "building0-floor1": () => import("./Floor_0_1"),
    "building1-floor0": () => import("./Floor_1_0"),
  },
  data() {
    return {
      exhibition: this.$root.$data.exhibition,
      buildingStrings: this.$root.$data.strings.building,
      scale: 1,
      avgScale: 4,
      maxScale: 10,
      scaleStop: 1,
      displayLevel: 0,
      showsPoi: this.poi !== undefined,
      selectedPoi: this.getPoiFromPath(),
    };
  },
  computed: {
    buildingClass() {
      if (this.showsPoi) {
        return "building-noscroll";
      }
      return "building-scroll";
    },
    buildingHeader() {
      return {
        headline: this.exhibition.buildings[this.building].name,
        subheading: this.showsFloorSelector
          ? this.buildingStrings.subheading_long
          : this.buildingStrings.subheading,
      };
    },
    showsFloorSelector() {
      return this.exhibition.buildings[this.building].floors.length > 1;
    },
    floors() {
      return this.exhibition.buildings[this.building].floors;
    },
    currentFloor() {
      return "building" + this.building + "-" + "floor" + this.floor;
    },
    showZoomControl() {
      return this.displayLevel === 1;
    },
    assetForPoi() {
      return this.exhibition.getAssetWithId(this.selectedPoi.asset);
    },
  },
  methods: {
    didScale(event) {
      this.scale = event.scale;
      this.displayLevel = this.scale > this.scaleStop ? 1 : 0;
    },
    changeFloor(index) {
      this.$router.replace("/building/" + this.building + "/floor/" + index);
    },
    onRoomClick(rect) {
      if (this.displayLevel === 1) {
        return;
      }
      this.zoomIntoRoom(rect);
    },
    zoomIntoRoom(rect) {
      this.$refs.PinchZoom.zoomTo(
        rect.x + rect.width * 0.5,
        rect.y + rect.height * 0.5
      );
    },
    zoomBack(e) {
      e.stopPropagation();
      this.$refs.PinchZoom.toggleZoom();
    },
    getPoiFromPath() {
      if (!this.building || !this.floor || !this.room || !this.poi) return;

      const b = this.$root.$data.exhibition.buildings[this.building];
      const f = b.floors[this.floor];
      const r = f.rooms[this.room];
      return r.pois[this.poi];
    },
    getPoi(poi) {
      return this.exhibition.buildings[this.building].floors[this.floor].rooms[
        poi.room
      ].pois[poi.index];
    },
    getPoiPath(poi) {
      return (
        "/building/" +
        this.building +
        "/floor/" +
        this.floor +
        "/room/" +
        poi.room +
        "/poi/" +
        poi.index
      );
    },
    onPoiItem(poi) {
      this.selectedPoi = this.getPoi(poi);
      this.$router.replace(this.getPoiPath(poi));
      this.showsPoi = true;
    },
    onPoiClose() {
      this.$router.replace(
        "/building/" + this.building + "/floor/" + this.floor
      );
      this.showsPoi = false;
    },
  },
};
</script>

<style>
#floor-zoomer-div {
  display: block;
  width: 100%;
  position: relative;
  height: calc(100vh - var(--height-header));
  min-height: 40vw;
  background-color: var(--color-background-alpha);
}

.building-scroll {
  overflow: hidden;
}

.building-noscroll {
  overflow: hidden;
}

.zoom-control {
  position: absolute;
  right: 1em;
  bottom: 1em;
  width: 80px;
  height: 80px;
  border-radius: 6px;

  color: #fff;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgc3R5bGU9IiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZD0iTTE1LjUgMTRoLS43OWwtLjI4LS4yN0MxNS40MSAxMi41OSAxNiAxMS4xMSAxNiA5LjUgMTYgNS45MSAxMy4wOSAzIDkuNSAzUzMgNS45MSAzIDkuNSA1LjkxIDE2IDkuNSAxNmMxLjYxIDAgMy4wOS0uNTkgNC4yMy0xLjU3bC4yNy4yOHYuNzlsNSA0Ljk5TDIwLjQ5IDE5bC00Ljk5LTV6bS02IDBDNy4wMSAxNCA1IDExLjk5IDUgOS41UzcuMDEgNSA5LjUgNSAxNCA3LjAxIDE0IDkuNSAxMS45OSAxNCA5LjUgMTR6IiBpZD0ic3ZnXzEiIGNsYXNzPSIiIGZpbGw9IiNmZmZmZmYiIGZpbGwtb3BhY2l0eT0iMSIvPjxwYXRoIGQ9Ik0xMiAxMGgtMnYySDl2LTJIN1Y5aDJWN2gxdjJoMnYxeiIgaWQ9InN2Z18zIiBjbGFzcz0iIiBmaWxsPSIjZmZmZmZmIiBmaWxsLW9wYWNpdHk9IjEiLz48L2c+PC9zdmc+),
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZD0iTTE1LjUgMTRoLS43OWwtLjI4LS4yN0MxNS40MSAxMi41OSAxNiAxMS4xMSAxNiA5LjUgMTYgNS45MSAxMy4wOSAzIDkuNSAzUzMgNS45MSAzIDkuNSA1LjkxIDE2IDkuNSAxNmMxLjYxIDAgMy4wOS0uNTkgNC4yMy0xLjU3bC4yNy4yOHYuNzlsNSA0Ljk5TDIwLjQ5IDE5bC00Ljk5LTV6bS02IDBDNy4wMSAxNCA1IDExLjk5IDUgOS41UzcuMDEgNSA5LjUgNSAxNCA3LjAxIDE0IDkuNSAxMS45OSAxNCA5LjUgMTR6TTcgOWg1djFIN3oiIGlkPSJzdmdfMiIgY2xhc3M9IiIgZmlsbD0iI2ZmZmZmZiIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg==);
  background-color: rgba(0, 0, 0, 0.8);
  background-position: -1000px, center;
  background-repeat: no-repeat, no-repeat;
  background-size: 40px;

  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.1s;
  user-select: none;
}
.zoom-control:hover,
.zoom-control:active {
  opacity: 0.8;
}
</style>
