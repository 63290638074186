<template>
  <div class="image-button" @click="onClick">
    <img class="image-button-image" :src="image" />
    <div class="image-button-overlay"></div>
  </div>
</template>

<script>
export default {
  props: ["image"],
  name: "ImageButton",
  methods: {
    onClick() {
      this.$emit("onImageButton");
    },
  },
};
</script>

<style scoped>
.image-button {
  box-shadow: 0 4px 8px 0 var(--color-medium-alpha),
    0 6px 20px 0 var(--color-medium-light-alpha);
  width: 100%;
  position: relative;
}

.image-button-image {
  width: 100%;
  display: block;
  position: relative;
}

.image-button:hover .image-button-overlay {
  opacity: 1;
}

.image-button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-dark-alpha);
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
