import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Exhibition from './model/exhibition'

const strings = require("../public/assets/data/strings.json");

import './css/style.css'
import 'video.js/dist/video-js.css'
import './css/videojs.css'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  data: {
    strings: strings,
    exhibition: new Exhibition()
  }
}).$mount('#app')
