<template>
  <li id="credit-list">
    <p id="credit-headline">{{ entry.headline }}</p>
    <div>
      <ul id="credit-sublist">
        <li v-for="name in entry.names" :key="name">
          <p>{{ name }}</p>
        </li>
      </ul>
      <p>{{ entry.subtitle }}</p>
    </div>
  </li>
</template>

<script>
export default {
  props: ["entry"],
  name: "CreditItem",
};
</script>

<style scoped>
#credit-list {
  margin: var(--margin-vertical) var(--inset-main);
}

#credit-list > p {
  font-family: Maxima;
}

li > div {
  margin-left: 1em;
}

#credit-sublist p {
  font-size: var(--font-size-medium);
  margin: 0.5em auto;
}

div > p {
  font-size: var(--font-size-small);
}

@media screen and (max-width: 660px) {
  #credit-list > p {
    margin: var(--margin-vertical) 0;
    text-align: center;
  }
  li > div {
    margin-left: 0em;
  }
  #credit-sublist p {
    text-align: center;
  }
  div > p {
    text-align: center;
  }
}
</style>
