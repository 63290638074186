import Vue from 'vue';
import Router from 'vue-router';

import Home from './components/Home.vue';
import Introduction from './components/Introduction.vue';
import Tour from './components/Tour.vue';
import Building from './components/Building.vue'
import Timeline from './components/Timeline.vue';
import Imprint from './components/Imprint.vue';
import Credits from './components/Credits.vue';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/introduction',
            name: 'introduction',
            component: Introduction
        },
        {
            path: '/tour',
            name: 'tour',
            component: Tour
        },
        {
            path: '/tour/poi/:poi',
            name: 'tour+poi',
            component: Tour,
            props: true
        },
        {
            path: '/building/:building/floor/:floor',
            name: 'building',
            component: Building,
            props: true
        },
        {
            path: '/building/:building/floor/:floor/room/:room/poi/:poi',
            name: 'building',
            component: Building,
            props: true
        },
        {
            path: '/timeline',
            name: 'timeline',
            component: Timeline,
            props: true
        },
        {
            path: '/timeline/:event',
            name: 'timeline',
            component: Timeline,
            props: true
        },
        {
            path: '/imprint',
            name: 'imprint',
            component: Imprint
        },
        {
            path: '/credits',
            name: 'credits',
            component: Credits
        }
    ],
    mode: 'history'
});
