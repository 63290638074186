<template>
  <div class="event">
    <div class="personal">
      <div
        class="personal-event"
        v-if="asset !== undefined"
        @click="onPersonal"
      >
        <div v-if="asset.video" class="timeline-player">
          <img :src="thumb" />
          <img src="/assets/svgs/play_button.svg" />
        </div>
        <p>{{ assetName }}</p>
      </div>
    </div>
    <div :class="personalSeparatorClass" />
    <p class="date-line">
      <span class="date-text">{{ event.date }}</span>
    </p>
    <div :class="historicalSeparatorClass" />
    <p class="historical">{{ event.historical }}</p>
  </div>
</template>

<script>
export default {
  name: "TimlineEvent",
  props: ["event", "asset"],
  computed: {
    personalSeparatorClass() {
      return this.event.personal === null
        ? "no-separator"
        : "personal-separator";
    },
    historicalSeparatorClass() {
      return this.event.historical === null
        ? "no-separator"
        : "historical-separator";
    },
    showsAsset() {
      return this.event.personal !== null;
    },
    assetName() {
      return this.asset === undefined ? "" : this.asset.name;
    },
    thumb() {
      return this.asset === undefined
        ? ""
        : "/assets/images/" + this.asset.video + ".jpg";
    },
  },
  data() {
    return {};
  },
  methods: {
    onPersonal() {
      this.$emit("onTimelineEvent", this.event);
    },
  },
};
</script>

<style scoped>
p {
  white-space: normal;
}

.event {
  width: 30em;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.personal {
  flex-grow: 4;
  position: relative;
  height: 50%;
}

.historical {
  flex-grow: 1;
  width: 20em;
  height: 25%;
  word-wrap: break-word;
  margin-left: 4em;
}

/* --------------- */

.personal-event {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  margin: 1em;
  margin-left: 5em;
}

.timeline-player {
  position: relative;
}

img:nth-child(1) {
  width: 100%;
  height: auto;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px 0 var(--color-medium-alpha),
    0 2px 5px 0 var(--color-medium-light-alpha);
  opacity: 1;
}

img:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4em;
  height: 4em;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

img:nth-child(1):hover {
  opacity: 0.8;
}

/* ------------- */

.date-line {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  text-align: left;
  margin-right: 0;
}

.date-text {
  font-family: Maxima;
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  position: relative;
  padding-right: 1em;
  padding-left: 1em;
  margin-left: 2.7em;
  color: black;
}

.date-text:before,
.date-text:after {
  content: "";
  display: block;
  width: 1000px;
  position: absolute;
  top: 0.5em;
  border-top: 2px solid black;
}

.date-text:before {
  right: 100%;
}

.date-text:after {
  left: 100%;
}

/* ------------------------------ */

.no-separator {
  flex-grow: 1;
  width: 2px;
  height: 2em;
  margin: 1em 0.3em;
  margin-left: 5em;
  background-color: var(--color-background-light);
}

.personal-separator {
  flex-grow: 1;
  width: 2px;
  height: 2em;
  margin: 1em 0.3em;
  margin-left: 5em;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(133, 133, 133, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
}

.historical-separator {
  flex-grow: 1;
  width: 2px;
  height: 2em;
  margin: 1em 0.3em;
  margin-left: 5em;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(133, 133, 133, 1) 75%,
    rgba(255, 255, 255, 1) 100%
  );
}

@media screen and (max-width: 660px) {
  .event {
    width: 100vw;
    height: auto;
    margin-bottom: 2em;
  }
  .event > *:nth-child(1) {
    order: 2;
  }
  .event:nth-child(2) {
    order: 3;
  }
  .event:nth-child(3) {
    order: 1;
  }
  .event:nth-child(4) {
    order: 4;
  }
  .event:nth-child(5) {
    order: 5;
  }

  .personal {
    height: auto;
  }

  .historical {
    width: auto;
    height: auto;
  }

  .personal-event {
    position: relative;
  }

  .personal-separator {
    display: none;
  }

  .historical-separator {
    display: none;
  }

  .no-separator {
    display: none;
  }

  .date-text:after {
    border-top: none;
  }
}
</style>
